import { ProductsContainer } from "../components/specific/TemplateProducts/ProductsFiltered/Products.container"
import * as React from 'react'
import { graphql } from 'gatsby'
import {
  extractFilterProductAttribute,
  extractFilterVariantAttribute,
} from "../../config/helper/reducers"

/**
 * Template de génération des collection avec infinite scroll et filtre 
 */


//HACK : /!\ collectionIdString => pas de cast sur graphql, strapi declare ID en String saleor en ID 
export const query = graphql`
query ProductsByCollectionQuery($collectionId: ID, $collectionIdString: String) {
  allStrapiCollectionContent(
    filter: {collection: {saleor_id: {eq: $collectionIdString}}}
  ) {
    edges {
      node {
        description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        contenuSEO {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        liens_haut_de_page {
          name
          saleor_id
          slug
        }
        liens_bas_de_page {
          name
          saleor_id
          slug
        }
        faq {
          question {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          reponse {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        id
        categorie_mere {
          saleor_id
        }
      }
    }
  }
  saleor {
    products(
      first: 60
      filter: {isPublished: true, collections: [$collectionId]}
      sortBy: {direction: ASC, field: RATING}
      channel: "default-channel"
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...fragProductCollection
          thumbnail(size: 512) {
            url
            alt
          }
        }
      }
    }
  }
}

fragment fragProductCollection on SALEOR_Product {
  id
  name
  slug
  variants {
    metadata {
      key
      value
    }
    attributes {
      attribute {
        name
        slug
      }
      values {
        name
        slug
      }
    }
  }
  metadata {
    key
    value
  }
  seoTitle
  seoDescription
  isAvailable
  availableForPurchase
  isAvailableForPurchase
  pricing {
    onSale
    discount {
      net {
        amount
        currency
      }
    }
    priceRangeUndiscounted {
      start {
        gross {
          amount
        }
      }
    }
    priceRange {
      start {
        gross {
          amount
          currency
        }
      }
    }
  }
  attributes {
    values {
      name
      slug
    }
    values {
      name
      slug
    }
    attribute {
      name
      slug
    }
  }
}


`
function extractData(data) {

  let result = {}

  try {
    result = data.allStrapiCollectionContent.edges.map(
      ({ node }) => {
        return {
          description: node.description?.data.childMarkdownRemark.html,
          contenuSEO:
            node.contenuSEO?.data.childMarkdownRemark.html,

          liens_haut_page: node.liens_haut_de_page?.map(({ name, slug }) => ({ name, slug })),
          liens_bas_page: node.liens_bas_de_page?.map(({ name, slug }) => ({ name, slug })),
          faq: node.faq?.map(({ question, reponse }) => ({ "question": question?.data.childMarkdownRemark.html, "reponse": reponse?.data.childMarkdownRemark.html })),
        }
      }
    )[0]

  } catch (error) {
    console.warn("some data from CollectionContent are missing", error)
  }

  return result as {
    description: string,
    contenuSEO: string,
    liens_haut_page: any,
    liens_bas_page: any,
    faq: { question: string, reponse: string }
  }


}

const productsFromCollectionFilter = ({ pageContext, data }) => {
  // calcul de la liste d'id de productType a utiliser pour chercher les filtres
  let attributesValableFromProduct =
    data.saleor.products.edges.reduce(
      extractFilterProductAttribute,
      {}
    )
  let attributesValableFromVariant =
    data.saleor.products.edges.reduce(
      extractFilterVariantAttribute,
      {}
    )



  pageContext = {
    ...pageContext,
    ...extractData(data),
    ... {
      filters: {
        ...attributesValableFromProduct,
        ...attributesValableFromVariant,
      }
    }
  }


  // TODO : merge pageContext et data + refaire l'interface Props 
  return <ProductsContainer pageContext={pageContext} data={data} />

}


export default productsFromCollectionFilter
